import React, { useState } from 'react';

import {Card, Image, Button, CardTitle, Container, Row, Col, CardBody, Input} from 'reactstrap';

const RomanConverter = () => {
  const [numberInput, setNumberInput] = useState('');
  const [romanInput, setRomanInput] = useState('');
  const [conversionResult, setConversionResult] = useState('');

  const handleNumberChange = (event) => {
    const value = event.target.value;
    setNumberInput(value);
    convertNumberToRoman(value);
  };

  const handleRomanChange = (event) => {
    const value = event.target.value.toUpperCase();
    setRomanInput(value);
    convertRomanToNumber(value);
  };

  const convertNumberToRoman = (number) => {
    // Function to convert number to Roman numerals
    // Implement your conversion logic here
    // This is just a basic example, you may want to enhance it for larger numbers
    const romanNumerals = ['I', 'IV', 'V', 'IX', 'X', 'XL', 'L', 'XC', 'C', 'CD', 'D', 'CM', 'M'];
    const arabicNumbers = [1, 4, 5, 9, 10, 40, 50, 90, 100, 400, 500, 900, 1000];

    let result = '';
    let num = parseInt(number, 10);

    for (let i = arabicNumbers.length - 1; i >= 0; i--) {
      while (num >= arabicNumbers[i]) {
        result += romanNumerals[i];
        num -= arabicNumbers[i];
      }
    }

    setConversionResult(result);
  };

  const convertRomanToNumber = (roman) => {
    // Function to convert Roman numerals to number
    // Implement your conversion logic here
    // This is just a basic example, you may want to enhance it for more complex cases
    const romanNumerals = {
      'I': 1,
      'V': 5,
      'X': 10,
      'L': 50,
      'C': 100,
      'D': 500,
      'M': 1000,
    };

    let result = 0;

    for (let i = 0; i < roman.length; i++) {
      const currentNumeral = romanNumerals[roman[i]];
      const nextNumeral = romanNumerals[roman[i + 1]];

      if (nextNumeral && currentNumeral < nextNumeral) {
        result += (nextNumeral - currentNumeral);
        i++; // Skip the next numeral since it's already considered
      } else {
        result += currentNumeral;
      }
    }

    setConversionResult(result);
  };

  return (
    <Container className="p-4"id='white' style={{height: "500px" }}>
        <Row>
            <h1>Roman Numeral Converter</h1>
            <p id="white"> Enter a number or roman numeral below to convert it.</p>
        </Row>
        <Row>
            <Col>
            <label>
                Enter Number: 
                <input type="text" value={numberInput} onChange={handleNumberChange} className='mx-2' />
            </label>
            </Col>
            <Col>
            <label>
                Enter Roman Numeral: 
                <input type="text" value={romanInput} onChange={handleRomanChange} className='mx-2' />
            </label>
            </Col>
            <Col>
            </Col>
        </Row>
        <Row>
            <div className='my-3'>
                <p id="white">Conversion Result: {conversionResult}</p>
            </div>
        </Row>
    </Container>
  );
};

export default RomanConverter;
