// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";

import { getDatabase, ref, set } from "firebase/database";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration

const firebaseConfig = {
      apiKey: "AIzaSyDfcJQdvZ6bsHfLOECqFxDvwOARW5ntIxY",
      authDomain: "roadtrip-f72c5.firebaseapp.com",
      projectId: "roadtrip-f72c5",
      storageBucket: "roadtrip-f72c5.appspot.com",
      messagingSenderId: "109761594601",
      appId: "1:109761594601:web:5f637fd6e2178d93b2dc78",
      measurementId: "G-Z87WHK9EBS"
    };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// export const auth = getAuth(app);
export default app;

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
// Initialize Firebase Auth provider
const provider = new GoogleAuthProvider();
  
// whenever a user interacts with the provider, we force them to select an account
provider.setCustomParameters({   
    prompt : "select_account "
});





export const auth = getAuth();


// export const db = getDatabase(app);



export const signInWithGooglePopup = () => signInWithPopup(auth, provider);


// // import firebase from "firebase/app";
// // import "firebase/auth";

// // const firebaseConfig = {
// //     apiKey: "AIzaSyDfcJQdvZ6bsHfLOECqFxDvwOARW5ntIxY",
// //     authDomain: "roadtrip-f72c5.firebaseapp.com",
// //     projectId: "roadtrip-f72c5",
// //     storageBucket: "roadtrip-f72c5.appspot.com",
// //     messagingSenderId: "109761594601",
// //     appId: "1:109761594601:web:5f637fd6e2178d93b2dc78",
// //     measurementId: "G-Z87WHK9EBS"
// //   };

// // firebase.initializeApp(firebaseConfig);

// // export const auth = firebase.auth();
// // export const googleProvider = new firebase.auth.GoogleAuthProvider();

// // Import specific Firebase functions and objects from 'firebase/app' and 'firebase/auth'
// import { initializeApp } from 'firebase/app';
// import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
// import firebase from "firebase/app";


// const firebaseConfig = {
//   apiKey: "AIzaSyDfcJQdvZ6bsHfLOECqFxDvwOARW5ntIxY",
//   authDomain: "roadtrip-f72c5.firebaseapp.com",
//   projectId: "roadtrip-f72c5",
//   storageBucket: "roadtrip-f72c5.appspot.com",
//   messagingSenderId: "109761594601",
//   appId: "1:109761594601:web:5f637fd6e2178d93b2dc78",
//   measurementId: "G-Z87WHK9EBS"
// };

// // Initialize Firebase using the 'initializeApp' function
// const app = initializeApp(firebaseConfig);

// // Get the authentication object using the 'getAuth' function
// const auth = getAuth(app);

// // // Create a GoogleAuthProvider using the 'GoogleAuthProvider' class
// // const googleProvider = new GoogleAuthProvider();

// // export { auth, googleProvider };


// // firebase.initializeApp(firebaseConfig);

// // export const auth = firebase.auth();

// const provider = new firebase.auth.GoogleAuthProvider();
// provider.setCustomParameters({ prompt: 'select_account' });

// export const signInWithGoogle = () => auth.signInWithPopup(provider);



