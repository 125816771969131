import { Card, Input, Button, Container, Row, Col, CardTitle, CardBody, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { useState, useEffect } from 'react';


function DayMap4 () {

    


    function getRandomColor() {
        // Generating a random hex color code
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
          color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
      }
      
      // Example usage:
      const randomColor = getRandomColor();

      console.log( localStorage.getItem('formula33'))
  
      const [data, setData] = useState(
        localStorage.getItem('formula33') ? JSON.parse(localStorage.getItem('formula33')) : [
          { id: 1, title: 'Untitled Project', list: [' '], color: randomColor }
        ]
      );

      

    //   const [data, setData] = useState([
    //     { id: 1, title: 'Untitled Project', list: [' '], color: randomColor }
    //   ]);

      useEffect(() => {
        
        const savedData = localStorage.getItem('formula33');
        
      
        if (savedData) {
          setData(JSON.parse(savedData));
          console.log('saved data:', savedData); // Log the retrieved data
        }
      }, []);

      useEffect(() => {
        console.log('Saving to local storage:', data);
        localStorage.setItem('formula33', JSON.stringify(data));

      }, [data]);

      const [dropdownOpen, setDropdownOpen] = useState(() => new Array(data.length).fill(false)); // State for dropdowns

  const toggleDropdown = (index) => {
    const newDropdownState = [...dropdownOpen];
    newDropdownState[index] = !newDropdownState[index];
    setDropdownOpen(newDropdownState);
  };

  const handleDeleteCard = (cardId) => {
    const newData = data.filter(item => item.id !== cardId);
    setData(newData);
  };

      const handleAddArray = () => {
        // You can implement a form or other user interface element to capture the new data
        const newData = {
          id: data.length + 1,
          title: 'Untitled Project', // Set a default title or provide a way for the user to input it
          list: [' '], // Set default items or provide a way for the user to input them
          color: randomColor
        };
    
        setData([...data, newData]);
      };

      const handleTitleChange = (id, event) => {
        const newData = data.map(item => {
          if (item.id === id) {
            return { ...item, title: event.target.value };
          }
          return item;
        });
    
        setData(newData);
      };

     

      let total = 0
      let totalCheckedAll = 0

      data.map(item => {
        const number = item.list.length
        const checkedNum = item.list.filter(item => item && item.checked === true);
        const totalChecked = checkedNum.length
        totalCheckedAll = totalCheckedAll + totalChecked
        total = total + number - totalChecked
      })

      console.log("total " + total)


      const handleTitleFocus = (event) => {
        event.target.select();
      };

      const handleInputFocus = (event) => {
        event.target.select();
      };

      const handleListItemChange = (id, index, event) => {
        const newData = data.map(item => {
          if (item.id === id) {
            const newList = [...item.list];
            newList[index] = { text: event.target.value, checked: event.target.checked };
            return { ...item, list: newList };
          }
          return item;
        });
    
        setData(newData);
      };
  

      const handleAddItem = (id) => {
        const newData = data.map(item => {
          if (item.id === id) {
            const newList = [...item.list, ' '];
            return { ...item, list: newList };
          }
          return item;
        });
      
        setData(newData);
      };
      

      const handleCheckboxChange = (id, index) => {
        const newData = data.map(item => {
          if (item.id === id) {
            const newList = [...item.list];
            newList[index] = { ...newList[index], checked: !newList[index].checked };
            return { ...item, list: newList };
          }
          return item;
        });
      
        setData(newData);
      };
      
      const handleTextChange = (id, index, event) => {
        const newData = data.map(item => {
          if (item.id === id) {
            const newList = [...item.list];
            newList[index] = { ...newList[index], text: event.target.value };
            return { ...item, list: newList };
          }
          return item;
        });
      
        setData(newData);
      };

   
      const reset = () => {
        setData([{ id: 1, title: 'Untitled Project', list: [' '], color: randomColor }])
      }
      
      
    
    return (
        // <Container style={{ height: "100%", width: "100vw", backgroundColor: "#FAF9F6"}}>
            <Container fluid className="custom-container" style={{backgroundColor: "#FAF9F6"} }>
            <Row>
              <Col xs={1}>
              </Col>
              <Col>
              <h1 className='my-5'>Create Your To Do Lists</h1>
              </Col>
            </Row>
            <Row>
              <Col xs={1}>
              </Col>
              <Col xs={2}>
                <Card className='my-3'>
                  <CardBody>
                    <Row>
                      Done: {totalCheckedAll}
                    </Row>
                    <Row>
                    To do: {total}
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col>
                {/* <Button onClick={reset} >Reset</Button> */}
              </Col>
            </Row>
            <Row>
                <Col sm={1}>
                </Col>
                <Col>
            <Row className='my-0'>
                    {data.map((item, index) => (
                        <Col sm={4} key={item.id}>
                            <Card className='my-4 mx-2' style={{ border: 'none', outline: 'none', boxShadow: 'none', backgroundColor: item.color }}>
                            <CardTitle className='mt-3 mx-3'>
                                <Row>
                                <Col xs={10}>
                                    <Input
                                    type="text"
                                    value={item.title}
                                    onChange={(e) => handleTitleChange(item.id, e)}
                                    onFocus={handleInputFocus}
                                    style={{ border: 'none', outline: 'none', boxShadow: 'none', color: "white", backgroundColor: item.color }}
                                    />
                                </Col>
                                <Col>
                                    {/* Dropdown for menu */}
                                    <Dropdown isOpen={dropdownOpen[index]} toggle={() => toggleDropdown(index)}>
                                    <DropdownToggle tag="div" data-toggle="dropdown" aria-expanded={dropdownOpen[index]}>
                                        <div style={{ color: "white" }}><strong>...</strong></div>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem onClick={() => console.log(`Option 1 clicked for card ${index + 1}`)}>Change Color</DropdownItem>
                                        <DropdownItem onClick={() => handleDeleteCard(item.id)}>Delete Card</DropdownItem>
                                        {/* Add more DropdownItems as needed */}
                                    </DropdownMenu>
                                    </Dropdown>
                                </Col>
                                </Row>
                            </CardTitle>
                            <CardBody>
                                {item.list.map((listItem, index) => (
                                    <Row>
                                    <Col xs={1}>
                                    
                                    <Input
                                        type="checkbox"
                                        checked={listItem.checked}
                                        // style={{  marginTop: "10px" }}
                                        onChange={() => handleCheckboxChange(item.id, index)}
                                    />
                                    </Col>
                                    {' '}
                                    <Col>
                                    <Input
                                        type="text"
                                        value={listItem.text}
                                        onChange={(e) => handleTextChange(item.id, index, e)}
                                        style={{ 
                                                    border: 'none', 
                                                    outline: 'none', 
                                                    boxShadow: 'none', 
                                                    color: listItem.checked ? 'red' : 'white', // Change color to red if checked
                                                    textDecoration: listItem.checked ? 'line-through' : 'none',
                                                    backgroundColor: item.color, 
                                                    padding: "0px" 
                                                }}
                                        onFocus={handleTitleFocus}
                                        
                                    />
                                    </Col>
                                    </Row>
                                ))} 
                                    <Row>
                                        <Col xs={1}>
                                            <Button className="my-3" onClick={() => handleAddItem(item.id)} color="primary" >+</Button>
                                        </Col>
                                    </Row>
                            </CardBody>
                        </Card>
                    </Col>
                ))}
                <Col className='text-left'>
                    <Button className="my-3" onClick={handleAddArray} color="primary">+</Button>
                </Col>
            </Row>
            {/* <Row className='my-2'>
                <Col className='text-center'>
                    <Button className="my-3" onClick={handleAddArray} color="primary">Add Project</Button>
                </Col>
            </Row> */}
            </Col>
            <Col sm={1}>
            </Col>
            </Row>
        </Container>
        
    )

}

export default DayMap4;