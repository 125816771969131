import {Card, Image, Button, Container, Row, Col} from 'reactstrap';
import {useNavigate, Link } from 'react-router-dom';

import RightSide from './homeContent/rightSide';


function Privacy () {

    
    

   

    return (
        <Container>
            <Row className='my-4'>
                <Col sm={9}>
                    <div id="white">
                <h1>Beaver Galaxy Blog - Privacy Policy</h1>

<p id="white" >This Privacy Policy explains how Beaver Galaxy ("the Blog") collects, uses, and protects your personal information. By using the Blog, you agree to the terms outlined in this policy.</p>

<h2>Information We Collect</h2>
<p id="white" >We may collect the following types of information:</p>
<ul>
    <li>Personal information (e.g., name, email address) provided voluntarily by users.</li>
    <li>Non-personal information such as browser type, IP address, and device information collected automatically through cookies and other tracking technologies.</li>
</ul>

<h2>Use of Information</h2>
<p id="white" >We use the collected information for the following purposes:</p>
<ul>
    <li>To personalize user experience and deliver relevant content.</li>
    <li>To improve our website based on user feedback and usage patterns.</li>
    <li>To send periodic emails regarding blog updates, promotions, and other relevant information. Users can opt-out of receiving emails at any time.</li>
</ul>

<h2>Protection of Information</h2>
<p id="white" >We implement security measures to protect the confidentiality and integrity of your information. However, no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee absolute security.</p>

<h2>Third-Party Links</h2>
<p id="white" >The Blog may contain links to third-party websites. We are not responsible for the privacy practices or content of these external sites. Please review the privacy policies of these third parties for more information.</p>

<h2>Changes to Privacy Policy</h2>
<p id="white" >We reserve the right to update or modify this Privacy Policy at any time. Any changes will be effective immediately upon posting on the Blog. Users are encouraged to review this policy periodically for updates.</p>

<h2>Contact Information</h2>
<p id="white" >If you have any questions or concerns about our Privacy Policy, please contact us at [contact email].</p>

<p id="white" ><strong>Effective Date: March 5th, 2024</strong></p>
</div>
                </Col>
                <Col sm={3}>
                    <RightSide/>
                </Col>
            </Row>
        </Container>
        
    )

}

export default Privacy;
