import { Container, Row, Col} from 'reactstrap';


import RightSide from '../../homeContent/rightSide';
import BeaverFactsContent from './beaverFactsContent';

function BeaverFacts () {

    
    

   

    return (
        <Container>
            <Row className='my-4'>
                <Col sm={9}>
                    <BeaverFactsContent/>
                </Col>
                <Col sm={3}>
                    <RightSide/>
                </Col>
            </Row>
        </Container>
        
    )

}

export default BeaverFacts;
