import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Content from './components/content';
import firebase from "firebase/compat/app";
// Required for side-effects
import "firebase/firestore";

function App() {

  
  return (
    <div className='app'>
    <Content/>
    </div>
  );
}

export default App;
