
import {useNavigate } from 'react-router-dom';

import {
    BrowserRouter,
    Routes,
    Route,
  } from "react-router-dom";
  import React, {Component} from 'react';
  import  { useState } from "react";
  import {
    Navbar,
    NavItem,
    NavbarToggler,
    Collapse,
    NavLink,
    Nav,
    NavbarBrand,
    Container,
    Row,
    Col,
    Footer

} from 'reactstrap';

import { auth, signInWithPopup, googleProvider,  } from "./firebase";
import { getAuth, onAuthStateChanged } from "firebase/auth";

import Logo from '../assets/logo2.png'
import Home from './homeContent/home';
import Stay from './stay';
import City from './city';
import Roadtrip from './roadtrip';
import About from './about';
import Icon from '../assets/icon.png'
import Acount from './acount';
import Video from './videos';
import Roadtrip2 from './roadtripNew';
import Fox from './fox';
import Navbar1 from './navbar1';
import BeaverFacts from './Content/beaverFacts/beaverFacts';
import Signup from './signup';
import Login from './login';
import Saved from './saved';
import Test from './testPost';
import Jane from './Content/jane/jane';
import UtahMovie from './Content/utahmovie/utahMovie';
import ReactStrapCard from './reactstrapcard';
import WebsiteRunner from './Content/websiteRunner/websiteRunner';
import Bacon from './Content/bacon/bacon';
import FamilyActivities from './Content/familyActivities/familyActivities';
import DayMap from './Content/dayMap/dayMap';
import DayMap2 from './Content/dayMap/dayMap2';
import DayMap3 from './Content/dayMap/dayMap3';
import DayMap4 from './Content/dayMap/dayMap4';
import DayMap5 from './Content/dayMap/dayMap5';
import Terms from './Terms';
import Privacy from './privacy';
import RomanConverter from './roman';
// import ReactGA from 'react-ga';

import { doc, getDoc } from "firebase/firestore";

import db from './firebase2'; // Import the Firebase database instance

import { setDoc } from "firebase/firestore";




  export default function Content() {
// class Content extends Component {
//     // constructor(props) {
//     //     super(props);
//     //     this.state = {
//     //       shortName: "empty",
//     //     };
//     //   }
    // function RouteTracker() {
    //   ReactGA.pageview(window.location.pathname + window.location.search);
    //   return null;
    // }


    const [login, setLogin] = useState(false);
    const [shortName, setName] = useState('hello');
    const [shortName2, setName2] = useState('hello');
    const [data11, setData11] = useState('hello');
    const [isOpen, setIsOpen] = React.useState(false);
    const [destination, setDestination ] = useState('provo');
    const [usState, setUSState] = useState('utah');
    const [trip, setTrip] = useState('provoToSanDiego');
    const [arrayNum, setArrayNum] = useState([{language: 'c', color: 'red'}, {language: 'd', color: 'redy'}, {language: 'f', color: 'blue'}, {language: 'v', color: 'yellow'}]);
    const [stops, setStops] = useState();
    const [photoURL, setPhotoURL] = useState();
    const [token, setToken] = useState();

    const [nav, setNav] = useState(true);
    const [saved, setSaved] = useState([]);
    const [favorites, setFavorites] = useState([]);
    const [tokenValue, setTokenValue] = useState([]);
    


    const auth = getAuth();
    console.log(auth)
    

    onAuthStateChanged(auth, async(user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/auth.user
        const uid = user.uid;
        setLogin(true)
        // console.log("signed in")
        // console.log(user)
        // ...
        setPhotoURL(user.photoURL)

        setToken(user.accessToken)

        try {
          const docRef = doc(db, "users", token); // Use the user's UID as the document ID
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            // Document exists

            // console.log("Document data:", docSnap.data());
            let data = docSnap.data()
            
            let favoriteData = data.favorites
           
            
            // console.log("success")
          } else {
            // Document does not exist
            console.log("No such document!");
            let data = token;
            if (token) {
              setTokenValue(data.token)
            }

            try {
              const customDocName = token; // Replace with your desired document name
          
              const docRef = doc(db, "users", customDocName); // Specify the custom document name
              await setDoc(docRef, {
                user: { token },
                favorites: []
              });
          
              console.log("Document written with custom name: ", customDocName);
            } catch (e) {
              console.error("Error adding document: ", e);
            }
          }
          
        } catch (e) {
          console.error("Error fetching document: ", e);
          console.error("this"+ e.stack);  // Log the stack trace
        }
        
      } else {
        // User is signed out
        console.log("signed out")
        setLogin(false)
        // ...
      }
    });



   

    console.log(login)

        return (
            <>
            {/* <Test token={token}/> */}
            {nav &&  <Navbar1 setToken={setToken} setIsOpen={setIsOpen} isOpen={isOpen} login={login} photoURL={photoURL}/>}

            <BrowserRouter>
            <div>
            <Routes>
                {/* <Route component={RouteTracker} /> */}
                <Route  path="/" element={<Home/>}/>
                {/* <Route path="stayHome" element={<Stay usState={usState} setUSState={setUSState}/>}/> */}
                {/* <Route path="city" element={<City destination={destination} setDestination={setDestination} />}/> */}
                {/* <Route path="/city/:cityName" element={<City destination={destination} setDestination={setDestination} />}/> */}
                {/* <Route path="roadtrip1" element={<Roadtrip stops={stops} setStops={setStops} arrayNum={arrayNum} setArrayNum={setArrayNum} trip={trip} setTrip={setTrip} setDestination={setDestination} />}/> */}
                {/* <Route path="about" element={<About/>}/> */}
                {/* <Route path="acount" element={<Acount/>}/> */}
                {/* <Route path="video" element={<Video destination={destination} setDestination={setDestination}/>}/> */}
                {/* <Route path="roadtrip" element={<Roadtrip2 token={token} trip={trip} setTrip={setTrip} setSaved={setSaved}/>}/> */}
                {/* <Route path='book' element={<Stay setDatas={setData11} data11={data11} shortName45={shortName} shortName22={shortName2} setName2={setName2}/>}/>
                <Route path="author" element={<Town/>}/> */}
                {/* <Route path="fox" element={<Fox setNav={setNav} />}/> */}
                <Route path="beaverFacts" element={<BeaverFacts />}/>
                {/* <Route path='signup' element={<Signup/>}/> */}
                {/* <Route path='login' element={<Login/>}/> */}
                {/* <Route path='saved' element={<Saved/>}/> */}
                <Route path="jane-austin" element={<Jane/>}/>
                {/* <Route path="utah-movie-map" element={<UtahMovie/>}/> */}
                {/* <Route path="reactstrap-card" element={<ReactStrapCard />}/> */}
                {/* <Route path="websiteRunner" element={<WebsiteRunner />}/> */}
                {/* <Route path="bacon" element={<Bacon/>}/> */}
                {/* <Route path="familyActivities" element={<FamilyActivities/>}/> */}
                <Route path="dayMap" element={<DayMap4/>}/>
                <Route path='terms-of-use' element={<Terms/>}/>
                <Route path='privacy-policy' element={<Privacy/>}/>
                <Route path='roman-converter' element={<RomanConverter/>}/>
              
                

            </Routes>
            </div>
        </BrowserRouter>


        <footer className="navbar2">
          <Container>
            <Row>
              <Col sm={3}>
                <p id="white" className='p-3' >Email: beavergalaxycontact@gmail.com</p>
              </Col>
              <Col sm={3}>
                <a href='/terms-of-use' ><p id="white" className='p-3'>Terms of Use</p></a>
              </Col>
              <Col sm={3}>
              <a href='/privacy-policy' ><p id="white" className='p-3'>Privacy Policy</p></a>
              </Col>
              <Col className='text-right'>
                <p id="white" className='p-3 '> © 2023 Beaver Galaxy. All rights reserved.</p>
              </Col>
            </Row>
          </Container>
        </footer>
        </>
        );
      }