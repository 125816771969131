import {Card, Image, Button, Container, Row, Col} from 'reactstrap';
import {useNavigate, Link } from 'react-router-dom';


import RightSide from '../../homeContent/rightSide';
import JaneContent from './janeContent';

function Jane () {

    
    

   

    return (
        <Container>
            <Row className='my-4'>
                <Col sm={9}>
                    <JaneContent/>
                </Col>
                <Col sm={3}>
                    <RightSide/>
                </Col>
            </Row>
        </Container>
        
    )

}

export default Jane;
