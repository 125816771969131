import {Card, Image, Button, Container, Row, Col} from 'reactstrap';
import {useNavigate, Link } from 'react-router-dom';


function Saved (saved) {

    console.log('saved' + saved)

    const santaquinspanishfork = [
        {
            id: 0,
            name: "Rowley's Red Barn",
            image: require("../assets/rowleyNo.jpg"),
            website: "https://rowleysredbarn.com/",
            address: "901 S. 300 W. Santaquin, UT 84655",
            phone: "(801) 754-5511",
            blog: "",
            rating: "",
            featured: false,
            description: "Right off the highway, the iconic red barn sits on a picturesque farm. The barn is home to a country store where you can find a wide selection of homemade jams, honey, pickles, and ice cream.",
            city: "Santaquin & Spanish Fork",
            cityName: "santaquin",
            photoOK: "yes",
            youtube: "https://www.youtube.com/embed/if2zFVD985Y",
            youtubePic: "https://img.youtube.com/vi/if2zFVD985Y/0.jpg",
        },
        {
            id: 1,
        name: "Shri Shri Radha Krishna Temple",
        image: require("../assets/rowleyNo.jpg"),
        website: "https://www.utahkrishnas.org/",
        address: "311 W 8500 S, Spanish Fork, UT 84660",
        phone: "(801) 798-3559",
        blog: "",
        rating: "",
        featured: false,
        description: "Explore the Krishna Temple in Spanish Fork, a magnificent Hindu sanctuary located in Utah. Immerse yourself in its beautiful architecture and spirituality, offering an enriching cultural and religious experience for travelers.",
        city: "Spanish Fork",
        cityName: "spanishfork",
        photoOK: "yes",
        youtube: "https://www.youtube.com/embed/fGhx4axKUX8",
        youtubePic: "https://img.youtube.com/vi/fGhx4axKUX8/0.jpg",
        },
        {
            id: 2,
        name: "Adventure Heights All-Abilities Park",
        image: require("../assets/rowleyNo.jpg"),
        website: "https://www.spanishfork.org/departments/parkrec/parks/adv_heights.php",
        address: "1321 E Canyon Rd, Spanish Fork, UT 84660",
        phone: "(801) 804-4600",
        blog: "",
        rating: "",
        featured: false,
        description: "Discover Adventure Heights All Ability Park in Spanish Fork, a thrilling and inclusive destination designed for all ages and abilities. With its array of accessible activities and scenic surroundings, it promises an adventure-filled experience that welcomes everyone to embrace the joy of outdoor play.",
        city: "Spanish Fork",
        cityName: "spanishfork",
        photoOK: "yes",
        youtube: "https://www.youtube.com/embed/6MLwW90PYpg",
        youtubePic: "https://img.youtube.com/vi/6MLwW90PYpg/0.jpg",
        },
        {
            id: 3,
        name: "Spanish Oaks Reservoir",
        image: require("../assets/rowleyNo.jpg"),
        website: "https://www.spanishfork.org/departments/parkrec/parks/reservoir.php",
        address: "2931 Spanish Oaks Dr, Spanish Fork, UT 84660",
        phone: "(801) 804-4600",
        blog: "",
        rating: "",
        featured: false,
        description: "a picturesque and tranquil destination for outdoor activities like fishing and picnicking. This reservoir provides a perfect getaway to connect with nature and enjoy quality time with family.",
        city: "spanishfork",
        cityName: "Spanish Fork",
        photoOK: "yes",
        youtube: "https://www.youtube.com/embed/223CY7djnRI",
        youtubePic: "https://img.youtube.com/vi/223CY7djnRI/0.jpg",
        },
        
        

        
        
    ]

    const beaver = [
        {
            id: 4,
            name: "Cove Fort",
            image: require("../assets/shakespear.jpg"),
            website: "https://www.churchofjesuschrist.org/learn/historic-sites/utah/cove-fort?lang=eng",
            address: "Cove Fort, HC-74 Box 6500 Beaver, Utah 84713",
            phone: "435-438-5547",
            blog: "",
            rating: "",
            featured: false,
            description: " This unique fort was built in 1867 and served as a way station for travelers on the Mormon Pioneer and California National Historic Trails.  Today, visitors can take a guided tour of the fort and learn about its rich history, including the stories of the families who lived and worked there.",
            city: "Beaver",
            cityName: "beaver",
            photoOK: "yes",
            youtube: "https://www.youtube.com/embed/V9KJb1aVo4Y",
            youtubePic: "https://img.youtube.com/vi/V9KJb1aVo4Y/0.jpg",
        },
        {
            id: 5,
            name: "Fremont Indian State Park and Museum",
            image: require("../assets/fremont.jpg"),
            website: "https://stateparks.utah.gov/parks/fremont-indian/",
            address: "Fremont Indian State Park and Museum, 3820 Clear Creek Canyon Rd, Sevier, UT 84766",
            phone: "435-527-4631",
            blog: "",
            rating: "",
            featured: false,
            description: "The park is named after the Fremont Indians who left behind an impressive collection of rock art and ancient dwellings. Visitors can explore the park's many hiking trails, which wind through picturesque canyons and offer stunning views of the surrounding mountains.",
            city: "Beaver",
            cityName: "beaver",
            photoOK: "yes",
            youtube: "https://www.youtube.com/embed/qD3RgnAPL-8",
            youtubePic: "https://img.youtube.com/vi/qD3RgnAPL-8/0.jpg",
            
        },
    ]

    const cedarCity = [
        {
            id: 6,
            name: "Frontier Homestead Museum",
            image: require("../assets/fronteir.jpg"),
            website: "https://frontierhomestead.org/",
            address: "Frontier Homestead State Park Museum, `635 N Main St, Cedar City, UT 84721",
            phone: "(435) 586-9290",
            blog: "",
            rating: "",
            featured: false,
            description: "A blast from the past! You can explore a replica of a pioneer cabin, a blacksmith shop, a dairy barn, and even a schoolhouse. ",
            city: "Cedar City",
            photoOK: "yes",
            youtube: "https://www.youtube.com/embed/ijcXVGskswo",
            youtubePic: "https://img.youtube.com/vi/ijcXVGskswo/0.jpg",
        },
        {
            id: 0,
            name: "Utah Shakespeare Festival",
            image: require("../assets/shakespear.jpg"),
            website: "https://www.bard.org/",
            address: "Utah Shakespeare Festival, 195 W Center St, Cedar City, UT 84720",
            phone: "435-586-7878",
            blog: "",
            rating: "",
            featured: false,
            description: `This festival is a magical celebration of all things Shakespearean, with performances of his plays in a stunning outdoor amphitheater.

`,
            city: "Cedar City",
            photoOK: "yes",
            youtube: "https://www.youtube.com/embed/X_5YG3h5V8A",
            youtubePic: "https://img.youtube.com/vi/X_5YG3h5V8A/0.jpg",
        },
        {
            id: 0,
            name: "Cedar Breaks National Monument",
            image: require("../assets/shakespear.jpg"),
            website: "https://www.nps.gov/cebr/index.htm",
            address: "2390 West Highway 56 Suite #11 Cedar City, UT 84720",
            phone: "(435) 986-7120",
            blog: "",
            rating: "",
            featured: false,
            description: "A stunning natural wonder located in southwestern Utah. This breathtaking destination boasts a massive natural amphitheater that stretches over 3 miles in length and almost 2,500 feet deep, showcasing vibrant colors of orange, red, and pink.",
            city: "Cedar City",
            photoOK: "yes",
            youtube: "https://www.youtube.com/embed/IssaHv9Gm54",
            youtubePic: "https://img.youtube.com/vi/IssaHv9Gm54/0.jpg",
        },
    
    ]

    const stGeorge = [
        {
            id: 0,
            name: "Thunder Junction All Abilities Park",
            image: require("../assets/jolla.jpg"),
            website: "https://www.sgcity.org/cityparks/thunderjunction",
            address: "Thunder Junction All Abilities Park, 1851 S Dixie Dr, St. George, UT 84770",
            phone: "(435) 627-4575",
            blog: "",
            rating: "",
            featured: false,
            description: "Featuring dinosaur-themed attractions and universally accessible spaces that invite visitors of all ages and abilities to share in the magic of outdoor exploration and recreation. From towering T-Rex sculptures to sensory-rich play zones, this park fosters a harmonious blend of fun and accessibility for a memorable family experience",
            city: "St. George",
            cityName: "stGeorge",
            youtube: "https://www.youtube.com/embed/hvHP1MPOWkQ",
            youtubePic: "https://img.youtube.com/vi/hvHP1MPOWkQ/0.jpg",
        },
        {
            id: 0,
            name: "Red Hills Desert Garden",
            image: require("../assets/jolla.jpg"),
            website: "https://redhillsdesertgarden.com/",
            address: "Red Hills Desert Garden, 375 Red Hills Pkwy, St. George, UT 84770",
            phone: "(435) 673-3617",
            blog: "",
            rating: "",
            featured: false,
            description: "This botanical oasis invites wanderers to immerse themselves in the beauty of desert life while exploring winding pathways and educational exhibits that celebrate the intricate balance of nature.",
            city: "St George",
            cityName: "stGeorge",
            youtube: "https://www.youtube.com/embed/iMWOAoCXtrE",
            youtubePic: "https://img.youtube.com/vi/iMWOAoCXtrE/0.jpg",
        },
        {
            id: 0,
            name: "Nielsen's Frozen Custard",
            image: require("../assets/jolla.jpg"),
            website: "https://nielsensfrozencustard.com/pages/st-george-menu",
            address: "Nielsen's Frozen Custard, 445 E St George Blvd 102 Blvd #102, St. George, UT 84770",
            phone: "(435) 628-5579",
            blog: "",
            rating: "",
            featured: false,
            description: "Serving up velvety frozen custard in an array of flavors, this local gem promises a delectable journey back in time, perfect for satisfying sweet cravings on a warm desert day.",
            city: "St. George",
            cityName: "stGeorge",
            youtube: "https://www.youtube.com/embed/GJMA29tAZy4",
            youtubePic: "https://img.youtube.com/vi/GJMA29tAZy4/0.jpg",
        },
    ]

    const lasVegas = [
        {
            id: 0,
            name: "Lion Habitat Ranch",
            image: require("../assets/lion.jpg"),
            website: "https://lionhabitatranch.org/",
            address: "Lion Habitat Ranch inc, 382 Bruner Ave, Henderson, NV 89044",
            phone: "(702) 595-6666",
            blog: "",
            rating: "",
            featured: false,
            description: "Get up close and personal with some of the world's most magnificent animals. Located just a short drive from the Las Vegas Strip, this 8-acre ranch is home to over 40 lions, including white lions, as well as a variety of other exotic animals.",
            city: "Las Vegas",
            cityName: "lasVegas",
            youtube: "https://www.youtube.com/embed/d6uintiPqpM",
            youtubePic: "https://img.youtube.com/vi/d6uintiPqpM/0.jpg",

        },
        {
            id: 0,
            name: "Wet'n'Wild Las Vegas",
            image: require("../assets/wetWild.jpg"),
            website: "https://en.wikipedia.org/wiki/Wet_%27n_Wild_(Las_Vegas)",
            address: "Wet'n'Wild Las Vegas, 7055 S Fort Apache Rd, Las Vegas, NV 89148",
            phone: " (702) 979-1600",
            blog: "",
            rating: "",
            featured: false,
            description: "Over 25 thrilling attractions, including water slides, wave pools, and lazy rivers. The park features several adrenaline-pumping slides and there are several attractions designed for families and younger children.",
            city: "Las Vegas",
            cityName: "lasVegas",
            youtube: "https://www.youtube.com/embed/o-IyzdVKwaU",
            youtubePic: "https://img.youtube.com/vi/o-IyzdVKwaU/0.jpg",
        },
        {
            id: 0,
            name: "Marvel Avengers Station",
            image: require("../assets/marvel.jpg"),
            website: "https://www.stationattraction.com/",
            address: "Marvel Avengers S.T.A.T.I.O.N., 3300 S Las Vegas Blvd, Las Vegas, NV 89109",
            phone: "702-894-7626",
            blog: "",
            rating: "",
            featured: false,
            description: "A one-of-a-kind attraction that immerses visitors into the world of the Marvel Cinematic Universe. The Station features a variety of interactive exhibits and displays that allow you to explore the world of Marvel's most beloved superheroes, including Iron Man, Captain America, Thor, and the Hulk.",
            city: "Las Vegas",
            cityName: "Las Vegas",
            youtube: "https://www.youtube.com/embed/Aeb5vngSAQU",
            youtubePic: "https://img.youtube.com/vi/Aeb5vngSAQU/0.jpg",
        },
        {
            id: 0,
            name: "Omega Mart",
            image: require("../assets/marvel.jpg"),
            website: "https://meowwolf.com/visit/las-vegas",
            address: "Omega Mart, 3215 S. Rancho Drive, #100 Las Vegas, NV 89102",
            phone: "(866) 636-9969",
            blog: "",
            rating: "",
            featured: false,
            description: "An extraordinary retail destination in partnership with Meow Wolf, offering a one-of-a-kind fusion of art installations and retail spaces. Embark on a surreal shopping adventure where creativity and imagination intertwine, making it a must-visit destination for any curious traveler.",
            city: "Las Vegas",
            cityName: "lasVegas",
            youtube: "https://www.youtube.com/embed/2-le8zUWvoU",
            youtubePic: "https://img.youtube.com/vi/2-le8zUWvoU/0.jpg",
        },
    ]
    const sanDiego = [
        {
            id: 0,
            name: "La Jola Shores Beach",
            image: require("../assets/jolla.jpg"),
            website: "https://www.sandiego.org/explore/coastal/la-jolla.aspx",
            address: "La Jolla Shores Beach, San Diego, CA 92037",
            phone: "N/A",
            blog: "",
            rating: "",
            featured: false,
            description: "a picturesque coastal haven in San Diego, offering a wide expanse of golden sands, gentle waves, and vibrant marine life, perfect for sun-soaked relaxation and ocean adventures. ",
            city: "San Diego",
            cityName: "sanDiego",
            youtube: "https://www.youtube.com/embed/ZnwcuurVZNE",
            youtubePic: "https://img.youtube.com/vi/ZnwcuurVZNE/0.jpg",
        },
        {
            id: 0,
            name: "Mission Beach",
            image: require("../assets/soap.jpg"),
            website: "https://www.thesoapfactoryutah.com/",
            address: "Mission Beach, San Diego, CA",
            phone: "N/A",
            blog: "",
            rating: "",
            featured: false,
            description: "a vibrant coastal gem in San Diego, known for its lively boardwalk, sun-drenched sands, and a perfect blend of recreational activities, from beach volleyball and biking to surfing",
            youtube: "https://www.youtube.com/embed/vtXkkUqou9U",
            youtubePic: "https://img.youtube.com/vi/vtXkkUqou9U/0.jpg",
        },
        {
            id: 0,
            name: "Sea World",
            image: require("../assets/soap.jpg"),
            website: "https://seaworld.com/san-diego/",
            address: "Sea World, 500 Sea World Drive, San Diego, CA 92109",
            phone: "(619) 222-4732",
            blog: "",
            rating: "",
            featured: false,
            description: "SeaWorld is a renowned marine-themed park where visitors can dive into a world of aquatic wonders, experiencing captivating animal shows, thrilling rides, and educational exhibits that celebrate the beauty and diversity of marine life.",
            city: "San Diego",
            cityName: "sanDiego",
            youtube: "https://www.youtube.com/embed/KSIo7ttVEIM",
            youtubePic: "https://img.youtube.com/vi/KSIo7ttVEIM/0.jpg",
        },
        {
            id: 0,
            name: "San Diego Zoo",
            image: require("../assets/soap.jpg"),
            website: "https://zoo.sandiegozoo.org/",
            address: "San Diego Zoo, 2920 Zoo Dr, San Diego, CA 92101",
            phone: "(619) 231-1515",
            blog: "",
            rating: "",
            featured: false,
            description: "Home to a remarkable collection of animals from around the globe, set within lush, immersive habitats that offer an unparalleled opportunity for visitors to connect with nature and learn about conservation efforts.",
            city: "San Diego",
            cityName: "sanDiego",
            youtube: "https://www.youtube.com/embed/1QyncBh3Qt8",
            youtubePic: "https://img.youtube.com/vi/1QyncBh3Qt8/0.jpg",
        },
    ]

    const provo = [
        {
            id: 0,
            name: "Soap Factory",
            image: require("../assets/soap.jpg"),
            website: "https://thesoapfactory.business.site/",
            address: "Soap Factory, 1411 W Center St, Provo, UT 84601",
            phone: "tel:(801) 377-2494",
            blog: "",
            rating: "",
            featured: false,
            description: "Want to get clean and smell good while also having a blast? Head over to The Soap Factory! It's like Willy Wonka's factory, but instead of candy, they make soap! You can mix and match different scents and colors to create your perfect soap bar. Plus, you'll leave smelling like a fresh spring day, which is much better than smelling like a dirty sock.",
            city: "Provo",
            cityName: "provo",
            youtube: "https://www.youtube.com/embed/wJQCEpUxx44",
            youtubePic: "https://img.youtube.com/vi/wJQCEpUxx44/0.jpg"
        },
        {
            id: 1,
            name: "Quarry: Indoor Climbing Center",
            image: require("../assets/quarry.jpg"),
            website: "https://www.provo.org/community/parks-and-trails/quarry-park",
            address: "Quarry: Indoor Climbing Center, 1700 E 650 S, Provo, UT 84606",
            phone: "tel:(801) 852-6606",
            blog: "",
            rating: "",
            featured: false,
            description: "Want to feel like you're in a post-apocalyptic wasteland without all the actual danger and destruction? The Quarry has got you covered! This abandoned rock quarry has been turned into a park with hiking trails, a pond, and plenty of spots to sit and contemplate the meaning of life. Just don't fall in the pond, because who knows what kind of creatures lurk in there.",
            city: "Provo",
            cityName: "provo",
            youtube: "https://www.youtube.com/embed/6R3lIBSgRno",
            youtubePic: "https://img.youtube.com/vi/6R3lIBSgRno/0.jpg",
        },
        {
            id: 3,
            name: "Hruska's Kolaches",
            image: require("../assets/kolache.jpg"),
            website: " https://hruskaskolaches.com/",
            address: "Hruska's Kolaches, 434 W Center St, Provo, UT 84601",
            phone: "tel:(801) 607-2485",
            blog: "",
            rating: "",
            featured: false,
            description: "Want to satisfy your hunger with some Eastern European goodness? Hruska's Kolaches has got you covered! With a variety of savory and sweet kolaches, you'll feel like you're in Prague. Plus, the warm and fluffy dough will make you want to stay forever.",
            city: "Provo",
            cityName: "provo",
            youtube: "https://www.youtube.com/embed/4fXZ2rIMdqA",
            youtubePic: "https://img.youtube.com/vi/4fXZ2rIMdqA/0.jpg",
        },
        {
            id: 4,
            name: "Peaks Ice Arena",
            image: require("../assets/peak.jpg"),
            website: "https://www.provo.org/community/peaks-ice-arena",
            address: "Peaks Ice Arena, 100 N Seven Peaks Blvd, Provo, UT 84606",
            phone: "tel:(801) 852-7465",
            blog: "",
            rating: "",
            featured: false,
            description: "Want to skate like a pro and show off your moves on the ice? The Peaks Ice Arena is the place to be! It's like Dancing With the Stars, but with skates and fewer sequins. Plus, you can't beat the feeling of the wind in your hair as you glide across the ice. Just don't forget to wear a helmet, because falling on your head is never a good thing.",
            city: "Provo",
            cityName: "provo",
            youtube: "https://www.youtube.com/embed/bWPK7zWfs-g",
            youtubePic: "https://img.youtube.com/vi/bWPK7zWfs-g/0.jpg",
        },
        {
            id: 5,
            name: "BYU Creamery on 9th",
            image: require("../assets/byucream.jpg"),
            website: "https://creamery.byu.edu/",
            address: "BYU Creamery on 9th, 1209 N 900 E, Provo, UT 84602",
            phone: "tel:(801) 422-2663",
            blog: "",
            rating: "",
            featured: false,
            description: "Want to cool off and get your dairy fix? Head over to The BYU Creamery! They have all kinds of delicious ice cream flavors, like raspberry fudge chunk and mint brownie. Plus, you can pretend you're in an old-timey soda fountain and order a milkshake. Just don't forget to bring your poodle skirt and leather jacket.",
            city: "Provo",
            cityName: "provo",
            youtube: "https://www.youtube.com/embed/q3s3kvy8MbU",
            youtubePic: "https://img.youtube.com/vi/q3s3kvy8MbU/0.jpg",
        },
        {
            id: 6,
            name: "Provo City Library",
            image: require("../assets/library.jpg"),
            website: "https://www.provolibrary.com/",
            address: "Provo City Library, 550 N University Ave, Provo, UT 84601",
            phone: "tel:(801) 852-6650",
            blog: "",
            rating: "",
            featured: false,
            description: "Want to get lost in a good book and feel smart? Head over to the Provo City Library! They have all kinds of books, from classic literature to trashy romance novels. Plus, you can",
            city: "Provo",
            cityName: "provo",
            youtube: "https://www.youtube.com/embed/Ji-Gh-rHMlY",
            youtubePic: "https://img.youtube.com/vi/Ji-Gh-rHMlY/0.jpg",
        },
        // {
        //     id: 7,
        //     name: "Center Street Musical Theatre",
        //     image: require("../assets/theater.webp"),
        //     website: "https://centerstreetmt.com/",
        //     address: "Center Street Musical Theatre, 177 W Center St, Provo, UT 84601",
        //     phone: "tel:(801) 373-4485",
        //     blog: "",
        //     rating: "",
        //     featured: false,
        //     description: "Want to get entertained and show off your singing skills? Head over to Center Street Musical Theatre! They have live musical performances that are sure to make you tap your feet and sing along. Plus, you can pretend you're auditioning for American Idol and belt out your favorite showtunes. Just don't forget your jazz hands.",
        //     city: "Provo",
        //     cityName: "provo",
        //     youtube: "https://www.youtube.com/embed/bWPK7zWfs-g",
        //     youtubePic: "https://img.youtube.com/vi/bWPK7zWfs-g/0.jpg",
        // },
        {
            id: 9,
            name: "Provo Beach Resort: Family Fun",
            image: require("../assets/beach.jpg"),
            website: "https://provobeach.com/",
            address: "Provo Beach Resort, 4801 N University Ave #210, Provo, UT 84604",
            phone: "tel:(801) 224-5001",
            blog: "",
            rating: "",
            featured: false,
            description: "Want to have a beach day without leaving the landlocked state of Utah? Provo Beach has got you covered! With an indoor surfing simulator, a ropes course, laser tag, mini golf, and a giant arcade, you'll feel like you're at the beach... if the beach also had laser guns and neon lights. Come for the waves, stay for the fun!",
            city: "Provo",
            cityName: "provo",
            youtube: "https://www.youtube.com/embed/4EEVCdEsuq0",
            youtubePic: "https://img.youtube.com/vi/4EEVCdEsuq0/0.jpg",
        },
        {
            id: 10,
            name: "High Country Rafting Adventure",
            image: require("../assets/beach.jpg"),
            website: "https://www.viator.com/tours/Provo/High-Country-Adventure/d50801-132412P1?pid=P00094667&mcid=42383&medium=link&medium_version=selector&campaign=PrivateUtahHighCountryAdventure",
            address: "Provo Beach Resort, 4801 N University Ave #210, Provo, UT 84604",
            phone: "tel:(801) 224-5001",
            blog: "",
            rating: "",
            featured: false,
            description: "Want to have a beach day without leaving the landlocked state of Utah? Provo Beach has got you covered! With an indoor surfing simulator, a ropes course, laser tag, mini golf, and a giant arcade, you'll feel like you're at the beach... if the beach also had laser guns and neon lights. Come for the waves, stay for the fun!",
            city: "Provo",
            cityName: "Provo",
            youtube: "https://www.youtube.com/embed/PwVKMUyoNW0",
            youtubePic: "https://img.youtube.com/vi/PwVKMUyoNW0/0.jpg",
        },
        
    ];
   

    return (
        <Container>
            <Row>
                <Col>
                saved
                </Col>
            </Row>
        </Container>
      
    )

}

export default Saved;
