import {Card, Image, Button, Container, Row, Col} from 'reactstrap';
import {useNavigate, Link } from 'react-router-dom';
import React, { useEffect } from 'react';

function Fox (setNav) {
    const navigate = useNavigate();


    useEffect(() => {
        // Call setNav here if needed
       
    }, []);
    

    return (
        <Container>
            <Row className='my-5'>
                <iframe src='http://douglasanimation.com/CarbonTIME/' height="600px"></iframe>
            </Row>
        </Container>
      
    )

}

export default Fox;
