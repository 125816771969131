import {
    Navbar,
    NavItem,
    NavbarToggler,
    Collapse,
    NavLink,
    Nav,
    NavbarBrand,
    Container,
    Row,
    Col,
    Button,
} from 'reactstrap';
import Logo from '../assets/logo2.png'
import Beaver from '../assets/beaver.png'
import Icon from '../assets/icon.png'
// import {useNavigate, Link } from 'react-router-dom';
import BeaverIcon from '../assets/beaverIcon.png'
import BLogo from '../assets/BLogo.png'
import {  createUserWithEmailAndPassword, signOut  } from 'firebase/auth';
import { auth } from './firebase';




function Navbar1 ({setIsOpen, isOpen, login, photoURL, setToken}) {



    const handleLogout = () => {               
        signOut(auth).then(() => {
        // Sign-out successful.
            
            console.log("Signed out successfully")
            setToken('')
        }).catch((error) => {
        // An error happened.
        });
    }
    
    console.log(photoURL)

    // const handleGoogleSignIn = async () => {
    //     try {
    //       const result = await auth.signInWithPopup(auth,googleProvider);
    //       // You can access user information from 'result.user' if needed.
    //     } catch (error) {
    //       console.error(error);
    //     }
    //   };

    
    return(
    <>
<Navbar className="navbar" expand="md">
                <Container>
                    <Row>
                        <Col sm={3}>
                        <NavbarBrand href="/"><img src={BLogo} width="100%" /></NavbarBrand>
                        </Col>
                        <Col sm={8}>
                            {/* <NavbarToggler onClick={() => { setIsOpen(!isOpen) }} />
                            <Collapse isOpen={isOpen} navbar>
                                <Nav className="mr-auto" navbar>
                                    <NavItem>
                                        <NavLink href="/">Home</NavLink>
                                    </NavItem>
                                   
                                    <NavItem>
                                        <NavLink href="roadtrip">Stops in Route</NavLink>
                                    </NavItem>
                                    
                                    <NavItem>
                                        <NavLink href="about">About Us</NavLink>
                                    </NavItem>
                                    
                        
                                </Nav>
                            </Collapse> */}
                        </Col>
                            
                        <Col>
                        {/* <NavLink href='acount'><img src={Icon} width='40'></img></NavLink> */}
                        </Col>
                    </Row>
                </Container>
            </Navbar>
        <Navbar className="navbar2" expand="md">
        <Container>
            <Row>
                <Col sm={1}>
                {/* <NavbarBrand href="/"><img src={BLogo} width="100%" /></NavbarBrand> */}
                </Col>
                <Col sm={6}>
                    <NavbarToggler onClick={() => { setIsOpen(!isOpen) }} />
                    <Collapse isOpen={isOpen} navbar>
                        <Nav className="mr-auto" navbar>
                            <NavItem>
                                <NavLink href="/">Home</NavLink>
                            </NavItem>
                           
                            {/* <NavItem>
                                <NavLink href="roadtrip">Stops in Route</NavLink>
                            </NavItem>
                            
                            <NavItem>
                                <NavLink href="about">About Us</NavLink>
                            </NavItem> */}
                            
                
                        </Nav>
                    </Collapse>
                </Col>
                    
                
                <Col>
                    {/* {login ? (<Button id="white" href="saved">Saved</Button>)  : (<></>) } */}
                </Col>
                <Col>
                    {/* {login ? (<img width="40px"src={photoURL}/>)  : (<></>) } */}
                </Col>
                <Col>
                {/* <NavLink href='acount'><img src={Icon} width='40'></img></NavLink> */}
                            
                            {/* {login ? (<Button id="white" onClick={handleLogout}>Sign Out</Button>)  : (<Button href="signup">Sign In</Button>) } */}
                            
                
                </Col>
            </Row>
        </Container>
    </Navbar>
    </>
    )
}

export default Navbar1;
