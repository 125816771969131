import {Card, Image, Button, Container, Row, Col} from 'reactstrap';
import {useNavigate, Link } from 'react-router-dom';
import Beaver from '../../assets/beaver.png'
import BeaverIcon from '../../assets/beaverIcon.png'
import LeftSide from './leftSide';
import RightSide from './rightSide';

function Home ({avgRating}) {

    
    

   

    return (
        <Container>
            <Row className='my-4'>
                <Col>
                    <LeftSide/>
                </Col>
                <Col sm={3}>
                    <RightSide/>
                </Col>
            </Row>
        </Container>
        
    )

}

export default Home;
