import {Card, Image, Button, Container, Row, Col} from 'reactstrap';
import {useNavigate, Link } from 'react-router-dom';

import Beaver from '../../assets/beaver.png'
import Trump from '../../assets/donald.png'
import Movies from '../../assets/movieSet.jpg'
import Website from '../../assets/website.jpg'
import Bacon from '../../assets/bacon.jpg'
import FamilyFun from '../../assets/familyFun.jpg'
import Youtube from '../../assets/youtube.jpg'
import Todo from '../../assets/todo.jpg'
import Roman from '../../assets/roman.jpg'

import TypeApp from '../../assets/typeApp.png'
import TypePage from '../../assets/typePage.png'

// import { Helmet } from 'react-helmet';

function LeftSide () {


    return (
        <>
        {/* <Helmet>
                <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2739441431042452"
        crossorigin="anonymous"></script>
            </Helmet> */}
    
        <Row>
            
            <Col xs={12} sm={4} className='my-4'>
                <Row className='my-4'>
                <a href="/beaverFacts" className="custom-link">
                    <Card className="border-0">
                        <Row>
                            <img src={Beaver} id='beaver'/>
                        </Row>
                        <Row className='mx-1 mt-2'>
                            <Col sm={10}>
                                <h1 id="font1">10 Facts about Beavers</h1>
                            </Col>
                        </Row> 
                        <Row className='mx-1 my-1'>
                            <img src={TypePage} id='type'/>
                        </Row>
                        <Row className='my-2 mx-1'>
                            <p id='font2'>When we think of nature's engineers, beavers are often at the top of the list. Learn more about these rodents here.</p>
                        </Row>
                    </Card>
                </a>
                </Row>

                {/* <Row className='my-4'>
                    <a href="/bacon" className="custom-link">
                        <Card className="border-0">
                            <Row>
                                <img src={Bacon} id='beaver'/>
                            </Row>
                            <Row className='mx-1 mt-2'>
                                <Col sm={10}>
                                    <h1 id="font1">Types of Bacon</h1>
                                </Col>
                            </Row> 
                            <Row className='mx-1 my-1'>
                                <img src={TypeApp} id='type'/>
                            </Row>
                            <Row className='my-2 mx-1'>
                                <p id='font2'>Learn all about different types of bacon</p>
                            </Row>
                        </Card>
                    </a>
                </Row> */}

                <Row className='my-4'>
                <a href="https://www.youtube.com/channel/UCgxSBRxQSuW_yvTpr1Gsp_Q" className="custom-link">
                    <Card className="border-0">
                        <Row>
                            <img src={Youtube} id='beaver'/>
                        </Row>
                        <Row className='mx-1 mt-2'>
                            <Col sm={10}>
                                <h1 id="font1">Beaver Galaxy Youtube Videos</h1>
                            </Col>
                        </Row> 
                        <Row className='mx-1 my-1'>
                            <img src={TypePage} id='type'/>
                        </Row>
                        <Row className='my-2 mx-1'>
                            <p id='font2'>Learn some stuff! Explore the channel. Subscribe!</p>
                        </Row>
                    </Card>
                </a>
                </Row>

            </Col>

            {/* collumb 2 */}

            <Col xs={12} sm={4} className='my-4'>
                
                <Row className='my-4'>
                    <a href="/jane-austin" className="custom-link">
                        <Card className="border-0">
                            <Row>
                                <img src={Trump} id='beaver'/>
                            </Row>
                            <Row className='mx-1 mt-2'>
                                <Col sm={10}>
                                    <h1 id="font1">Trump - Regency Translator</h1>
                                </Col>
                            </Row> 
                            <Row className='mx-1 my-1'>
                                <img src={TypeApp} id='type'/>
                            </Row>
                            <Row className='my-2 mx-1'>
                                <p id='font2'>Watch Trump's quotes take a delightful detour to Regency-style charm with our online tool, adding a touch of humor and literary finesse to his words.</p>
                            </Row>
                        </Card>
                    </a>
                </Row>
                <Row className='my-4'>
                    <a href="/roman-converter" className="custom-link">
                        <Card className="border-0">
                            <Row>
                                <img src={Roman} id='beaver'/>
                            </Row>
                            <Row className='mx-1 mt-2'>
                                <Col sm={10}>
                                    <h1 id="font1">Roman Numeral Converter</h1>
                                </Col>
                            </Row> 
                            <Row className='mx-1 my-1'>
                                <img src={TypeApp} id='type'/>
                            </Row>
                            <Row className='my-2 mx-1'>
                                <p id='font2'>Convert numbers to roman numerals.</p>
                            </Row>
                        </Card>
                    </a>
                </Row>
            </Col>
            <Col xs={12} sm={4} className='my-4'>
                
                <Row className='my-4'>
                    <a href="/daymap" className="custom-link">
                        <Card className="border-0">
                            <Row>
                                <img src={Todo} id='beaver'/>
                            </Row>
                            <Row className='mx-1 mt-2'>
                                <Col sm={10}>
                                    <h1 id="font1">To Do Lists</h1>
                                </Col>
                            </Row> 
                            <Row className='mx-1 my-1'>
                                <img src={TypeApp} id='type'/>
                            </Row>
                            <Row className='my-2 mx-1'>
                                <p id='font2'>Make your own to do lists on this useful app.</p>
                            </Row>
                        </Card>
                    </a>
                </Row>
                {/* <Row className='my-4'>
                    <a href="/jane-austin" className="custom-link">
                        <Card className="border-0">
                            <Row>
                                <img src={Movies} id='beaver'/>
                            </Row>
                            <Row className='mx-1 mt-2'>
                                <Col sm={10}>
                                    <h1 id="font1">Movies filmed in Utah</h1>
                                </Col>
                            </Row> 
                            <Row className='mx-1 my-1'>
                                <img src={TypeApp} id='type'/>
                            </Row>
                            <Row className='my-2 mx-1'>
                                <p id='font2'>Ever wonder what movies where filmed near your house. Check out this interactive map.</p>
                            </Row>
                        </Card>
                    </a>
                </Row> */}
            </Col>
           

        </Row>
        
        </>
      
    )

}

export default LeftSide;
