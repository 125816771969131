import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// TODO: Replace the following with your app's Firebase project configuration
// See: https://support.google.com/firebase/answer/7015592
const firebaseConfig = {
    apiKey: "AIzaSyDfcJQdvZ6bsHfLOECqFxDvwOARW5ntIxY",
    authDomain: "roadtrip-f72c5.firebaseapp.com",
    projectId: "roadtrip-f72c5",
    storageBucket: "roadtrip-f72c5.appspot.com",
    messagingSenderId: "109761594601",
    appId: "1:109761594601:web:5f637fd6e2178d93b2dc78",
    measurementId: "G-Z87WHK9EBS"
  };
// Initialize Firebase
const app = initializeApp(firebaseConfig);


// Initialize Cloud Firestore and get a reference to the service
 const db = getFirestore(app);

 export default db;