import {Card, Image, Button, Container, Row, Col} from 'reactstrap';
import {useNavigate, Link } from 'react-router-dom';
// import { Helmet } from 'react-helmet';
import AdsComponent from '../adsComponent';

function RightSide () {


    return (
        <>
        <Row>
            <Col  className='my-4'>
                hello
            </Col>
            
        </Row>
        </>
      
    )

}

export default RightSide;