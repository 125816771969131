import React, { useState } from 'react';

function AutofillInput() {
  const [inputValue, setInputValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);

  const allOptions = ["blue", "yellow", "green", "orange", "red"];

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);

    // Filter the options that start with the current input value
    const filteredSuggestions = allOptions.filter(option =>
      option.toLowerCase().startsWith(value.toLowerCase())
    );

    setSuggestions(filteredSuggestions);
  };

  const handleSuggestionClick = (suggestion) => {
    setInputValue(suggestion);
    setSuggestions([]);
  };

  return (
    <div>
      <input
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        placeholder="Type here..."
      />
      <ul id="white">
        {suggestions.map((suggestion, index) => (
          <li key={index} onClick={() => handleSuggestionClick(suggestion)}>
            {suggestion}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default AutofillInput;
