import {Card, Image, Button, Container, Row, Col} from 'reactstrap';
import {useNavigate, Link } from 'react-router-dom';

import RightSide from './homeContent/rightSide';


function Terms () {

    
    

   

    return (
        <Container>
            <Row className='my-4'>
                <Col sm={9}>
                <h1 id="white" >Beaver Galaxy Blog - Terms of Use</h1>

<h2 id="white">1. Acceptance of Terms</h2>
<p id="white" >By accessing or using the Beaver Galaxy blog ("the Blog"), you agree to comply with and be bound by these Terms of Use. If you do not agree to these terms, please refrain from using the Blog.</p>

<h2 id="white" >2. Content Disclaimer</h2>
<p id="white" >The content provided on Beaver Galaxy is for informational purposes only. The blog owner and contributors are not responsible for any errors, omissions, or inaccuracies in the content. Users should use their discretion before relying on any information provided on the Blog.</p>

<h2 id="white" >3. User Conduct</h2>
<p id="white" >You agree not to engage in any of the following activities while using the Blog:</p>
<ul id="white" >
    <li>Violating any applicable laws or regulations.</li>
    <li>Posting or transmitting any content that is unlawful, harmful, threatening, abusive, harassing, defamatory, vulgar, obscene, or otherwise objectionable.</li>
    <li>Engaging in any form of unauthorized advertising or solicitation.</li>
</ul>

<h2 id="white" >4. Intellectual Property</h2>
<p id="white" >All content on Beaver Galaxy, including text, graphics, logos, images, and software, is the property of the blog owner and is protected by intellectual property laws. Users may not reproduce, distribute, or modify any content without prior written consent.</p>

<h2 id="white" >5. User Contributions</h2>
<p id="white" >By submitting content to Beaver Galaxy, you grant the blog owner a non-exclusive, royalty-free, perpetual, and worldwide license to use, reproduce, modify, adapt, publish, translate, and distribute the content in any medium.</p>

<h2 id="white" >6. Privacy</h2>
<p id="white" >Your use of the Blog is also governed by our Privacy Policy, which can be found <a href='/privacy-policy'>here.</a></p>

<h2 id="white" >7. Termination</h2>
<p id="white" >The blog owner reserves the right to terminate or suspend access to the Blog, without notice, for any user who violates these Terms of Use.</p>

<h2 id="white" >8. Changes to Terms</h2>
<p id="white" >The blog owner may revise and update these Terms of Use at any time. Users are encouraged to review the terms periodically for any changes.</p>

<h2 id="white" >9. Contact Information</h2>
<p id="white" >If you have any questions or concerns about these Terms of Use, please contact us at beavergalaxycontact@gmail.com.</p>

<p id="white" ><strong>Effective Date: March 5, 2024 </strong></p>
                </Col>
                <Col sm={3}>
                    <RightSide/>
                </Col>
            </Row>
        </Container>
        
    )

}

export default Terms;
