import React, { useState } from 'react';

import { Card, Image, Input, Button, Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';

import Trump from '../../../assets/donald.png'
import Trump2 from '../../../assets/donald2.jpg'



function JaneContent() {
  
    const [idVal, setIdVal] = useState("4trump");

    // function ChangeId(newTrump) {
    //     setIdVal(newTrump)
    // }

    

    const translated = [
        {
            id: 0,
            quote: "Some measure of moderation, one dare say, would be quite advantageous. Naturally, it must be acknowledged that my own existence has, in truth, not been one characterized by moderation.",
            untranslated: "A little more moderation would be good. Of course, my life hasn't exactly been one of moderation."
        },
        {
            id: 1,
            quote: "The crux of the matter is that one simply cannot be excessively avaricious.",
            untranslated: "The point is that you can't be too greedy."
        },
        {
            id: 2,
            quote: "I do indeed possess a rather sizable constituency of agriculturists, a circumstance I find most felicitous. They hold a favorable disposition toward Trump, yet, in return, I maintain a certain fondness for their cause and shall extend my aid unto them.",
            untranslated: "I do have a very big farmer base, which is good. They like Trump, but I like them, and I’m going to help them."

        },
        {
            id: 3,
            quote: "I have recently had the pleasure of sitting for a series of charming portraits, wherein I exhibited a most generous countenance. I appeared cheerful, content, and, one might venture to say, a rather amiable individual, in accordance with my own self-assessment.",
            untranslated: "I had some beautiful pictures taken in which I had a big smile on my face. I looked happy, I looked content, I looked like a very nice person, which in theory I am."
        },
        {
            id: 4,
            quote: "The remarkable facet of my character, you see, is my exceedingly affluent disposition.",
            untranslated: "The beauty of me is that I’m very rich."
        },
        {
            id: 5,
            quote: "I dare say that, in my estimation, I possess a modicum of humility. It is my belief that my humility exceeds the comprehension of most.",
            untranslated: "I think I am actually humble. I think I’m much more humble than you would understand."

        },
        {
            id: 6,
            quote: "My father, I must admit, extended to me a rather modest pecuniary assistance, in the sum of one million dollars.",
            untranslated: "My father gave me a small loan of a million dollars."
        },
        {
            id: 7,
            quote: "I can assure you that I do not adorn a 'toupee'—for the hair upon my head is indeed my own. As for any discussions regarding your considerable endeavors in the realm of cosmetic surgery, I shall abstain from such discourse.",
            untranslated: "I don’t wear a 'rug'— it’s mine. And I promise not to talk about your massive plastic surgeries that didn’t work."
        },
        {
            id: 8,
            quote: "I possess a degree of intelligence, I assure you. Certain individuals might go so far as to describe it as exceptionally profound, exceedingly so, one could even say, very, very, very intelligent.",
            untranslated: "I’m intelligent. Some people would say I’m very, very, very intelligent."

        },
       
    ]


    

    let content;
    let content1;

    switch (idVal) {
        case '1trump':
            content = translated[0].quote;
            content1 = translated[0].untranslated
            console.log('yup')
          break;
        case '2trump':
            content = translated[1].quote;
            content1 = translated[1].untranslated;
          break;
        case '3trump':
            content = translated[2].quote;
            content1 = translated[2].untranslated;
          break;
        case '4trump':
            content = translated[3].quote;
            content1 = translated[3].untranslated;
          break;
        case '5trump':
            content = translated[4].quote;
            content1 = translated[4].untranslated;
          break;
          case '6trump':
            content = translated[5].quote;
            content1 = translated[5].untranslated;
          break;
          case '7trump':
            content = translated[6].quote;
            content1 = translated[6].untranslated;
          break;
          case '8trump':
            content = translated[7].quote;
            content1 = translated[7].untranslated;
          break;
          case '9trump':
            content = translated[8].quote;
            content1 = translated[8].untranslated;
          break;
        default:
          content = translated[3].quote;
          content1 = translated[3].untranslated;
      }
      
      console.log(content)

    
      const changeCardColor = (cardId) => {

        const card = document.getElementById(cardId);
      
        const allCards = document.querySelectorAll('.card')

        if (card.style.backgroundColor !== "blue") {
            card.style.backgroundColor = "blue";

            allCards.forEach((otherCard) => {
                if (otherCard !== card) {
                    otherCard.style.backgroundColor = "white";
                }
            });
            
        } 
        
        // const card = document.getElementById(cardId);
        // card.style.backgroundColor = "blue"; // Replace 'your-color' with the desired color


    };
      

    return(
        <Container>
            <Row>
                <Col>
                    <h1 className="white">Trump Quote Regency Translator</h1>
                </Col>
            </Row>
            <Row>
                <p className="white">Watch Trump's quotes take a delightful detour to Regency-style charm with our online tool, adding a touch of humor and literary finesse to his words.</p>
            </Row>
            <Row>
                <Col xs={3}>
                    <div>
                        <img width="100%" src={Trump2}></img>
                    </div>
                </Col>
                <Col xs={3}>
                
                </Col>
                <Col xs={3}>
                    <div>
                        <img width="100%" src={Trump}></img>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h3 className="white">Current English</h3>
                    <Card >
                            <p className='my-3 mx-3'>{content1}</p>

                    </Card>
                </Col>
                <Col>
                    <h3 className="white">Regency English Translation</h3>
                    <Card >
                        <p className='my-3 mx-3'>{content}</p>
                    </Card>
                </Col>
            </Row>

            
            
            <Row>
                <h2 className="my-3 white" ><strong>Select another quote below to translate Trump's words into Jane Austin's prose.</strong></h2>
            </Row>
            
            <Row className='my-5'>
                <Col>
                    <Card id='card1' className="card" onClick={() => {setIdVal('1trump'); changeCardColor('card1')}}>
                        <Row className='my-2 mx-1'>
                            <p id='1trump'>{translated[0].untranslated}</p>
                        </Row>
                    </Card>
                </Col>
                <Col>
                    <Card id='card2' className="card" onClick={() => {setIdVal('2trump'); changeCardColor('card2')}}>
                        <Row className='my-2 mx-1'>
                        <p id='2trump'>{translated[1].untranslated}</p>
                        </Row>
                    </Card>
                </Col>
                <Col>
                    <Card id='card3' className="card"  onClick={() => {setIdVal('3trump'); changeCardColor('card3')}}>
                        <Row className='my-2 mx-1'>
                        <p id='3trump'>{translated[2].untranslated}</p>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card id='card4' className="card" onClick={() => {setIdVal('4trump'); changeCardColor('card4')}}>
                        <Row className='my-2 mx-1'>
                        <p id='4trump'>{translated[3].untranslated}</p>
                        </Row>
                    </Card>
                </Col>
                <Col>
                    <Card id='card5' className="card" onClick={() => {setIdVal('5trump'); changeCardColor('card5')}}>
                        <Row className='my-2 mx-1'>
                            <p id='5trump'>{translated[4].untranslated}</p>
                        </Row>
                    </Card>
                </Col>
                <Col>
                    <Card id='card6' className="card"  onClick={() => {setIdVal('6trump'); changeCardColor('card6')}}>
                        <Row className='my-2 mx-1'>
                            <p id='6trump'>{translated[5].untranslated}</p>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Row className='my-5'>
                <Col>
                    <Card id='card7' className="card" onClick={() => {setIdVal('7trump'); changeCardColor('card7')}}>
                        <Row className='my-2 mx-1'>
                        <p id='7trump'>{translated[6].untranslated}</p>
                        </Row>
                    </Card>
                </Col>
                <Col>
                    <Card id='card8' className="card" onClick={() => {setIdVal('8trump'); changeCardColor('card8')}}>
                        <Row className='my-2 mx-1'>
                            <p id='8trump'>{translated[7].untranslated}</p>
                        </Row>
                    </Card>
                </Col>
                <Col>
                 <Card id='card9' className="card" onClick={() => {setIdVal('9trump'); changeCardColor('card9')}}>
                        <Row className='my-2 mx-1'>
                            <p id='9trump'>{translated[8].untranslated}</p>
                        </Row>
                    </Card>
                </Col>
            </Row>
            
        </Container>
    )

    }

export default JaneContent;