import {Card, Button, Container, Row, Col} from 'reactstrap';
import {useNavigate, Link } from 'react-router-dom';
import Beaver from '../../../assets/beaver.jpg'



function BeaverFactsContent () {

    
    

   

    return (
        <Row>
            <Col sm={2}>
                <a href='/'><div id="white">Back</div></a>
            </Col>
            <Col className='contentBack p-5'>
                <h1>10 Amazing Facts You Should Know About Beavers</h1>
                <p>When we think of nature's engineers, beavers are often at the top of the list. These industrious rodents have long fascinated scientists and nature enthusiasts alike with their incredible abilities and unique behaviors. While you may be familiar with their dam-building prowess, there's so much more to these creatures than meets the eye. In this article, we'll explore 10 amazing things you probably didn't know about beavers.</p>
                <img width="100%" className="my-3" src={Beaver}></img>
                <h2 className="my-3">1. Beavers are Superb Architects</h2>
                <p>Beavers are renowned for their engineering skills. They construct elaborate dams and lodges using sticks, mud, and stones. These structures not only provide shelter but also help regulate water levels and create habitat diversity for other wildlife.</p>
                <h2 className="my-3" >2. Beavers are Masters of Communication</h2>
                <p>Beavers use a complex system of vocalizations and tail slaps to communicate with one another. The tail slap, a loud smack on the water's surface, serves as an alarm signal to warn of danger.</p>
                <h2 className="my-3" >3. Rodent Renaissance</h2>
                <p>Beavers are the second-largest rodent species in the world, with only the South American <a href='https://en.wikipedia.org/wiki/Capybara'target='blank'>capybara</a> surpassing them in size. An adult beaver can weigh up to 70 pounds (32 kg) and measure around 3 to 4 feet (1-1.2 meters) in length.</p>
                <h2 className="my-3" >4. Beavers have sharp teeth</h2>
                <p>Beavers are equipped with incredibly strong and sharp teeth that never stop growing. They continuously gnaw on trees and vegetation to keep their teeth from overgrowing, which also serves as a vital source of food.</p>
                <h2 className="my-3" >5. Beavers have webbed feet</h2>
                <p>Their hind feet are webbed, making them excellent swimmers. Beavers can stay submerged for up to 15 minutes, allowing them to access underwater vegetation and escape from predators.</p>
                <h2 className="my-3" >6. Beavers are aquatic herbivores</h2>
                <p>Contrary to popular belief, beavers are primarily herbivores. Their diet consists of bark, leaves, aquatic plants, and shrubs. They are especially fond of aspen and willow trees</p>
                <h2 className="my-3" >7. Beavers are Ecological Engineers</h2>
                <p>Beavers have a profound impact on their ecosystems. Their dam-building activities create wetlands, which serve as vital habitats for various plants and animals. These wetlands also improve water quality by trapping sediments and filtering pollutants.</p>
                <h2 className="my-3" >8. Beavers are Nocturnal Natives</h2>
                <p>Beavers are predominantly nocturnal creatures, meaning they are most active at night. This behavior helps them avoid predators like wolves and coyotes, which are more active during the day.</p>
                <h2 className="my-3" >9. Beavers are Monogamous Mates</h2>
                <p>Beavers form strong family bonds. They typically mate for life and raise their kits together in lodges. Beaver families are often composed of the parents and their young from the previous few years.</p>
                <h2 className="my-3" >10. Beavers have Historical Importance</h2>
                <p>Beavers played a significant role in North American history. European fur trappers and traders highly valued their pelts, which led to overhunting and the near-extinction of the beaver in some regions. Conservation efforts have since helped these creatures make a comeback.</p>
                <h2 className="my-3" >Conclusion</h2>
                <p>In conclusion, beavers are not just the architects of the animal kingdom but also fascinating creatures with a host of unique characteristics. Their ecological importance cannot be overstated, as they continue to shape and enrich our natural world. So, the next time you spot a beaver dam or catch a glimpse of one of these remarkable rodents, take a moment to appreciate the many wonders of the beaver's world.</p>

            </Col>
            <Col sm={1}></Col>
        </Row>
        
    )

}

export default BeaverFactsContent;
